<script setup lang="ts">
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  type PropType,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { zoho } from '@dashboard/config'
import { useGetLegacyPeopleGroupByClub } from '@dashboard/modules/ProfileSelector/composables'
import { RoleKind, type AllowedRoleKind } from '@libs/fsc/types/user'

// ***
// DEFAULTS
// ***
const formRoutes = {
  incidence: zoho.incidenceFormUrl,
  'cluber-help': zoho.helpFormUrl,
}

const props = defineProps({
  formRoute: {
    type: String as PropType<keyof typeof formRoutes>,
    required: true,
  },
})

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const { currentUser } = useGetLegacyPeopleGroupByClub()

// ***
// DASHBOARD INFO
// ***
const DASHBOARD_ROUTE_NAME = 'managerOverview'
const resolvedRoute = router.resolve({ name: DASHBOARD_ROUTE_NAME, params: { clubHash: route.params.clubHash } })

// ***
// USER INFO
// ***
type AllowedSupportRoles = Exclude<AllowedRoleKind, 'ROLE_EXTERNAL' | 'ROLE_PLAYER' | 'ROLE_ADMIN'>

const roleDictionary : Record<AllowedSupportRoles, string> = {
  [RoleKind.Guardian]: t('Guardian'),
  [RoleKind.Member]: t('Member'),
  [RoleKind.Manager]: t('Manager'),
}

const userRole = route.meta.roleKind as AllowedSupportRoles

const userParams = computed(() => {
  const user = currentUser.value
  if (!user) return ''

  const userFields : Record<string, string> = {
    zf_rszfm: '1',
    clubName: user.clubName || '',
    clubId: route.params.clubHash as string || '',
    email: user.email || '',
    clubUrl: resolvedRoute.fullPath,
    phone: user.phone || '',
    name: `${user.name} ${user.lastname}`,
    personId: user.id || '',
    role: roleDictionary[userRole],
  }

  return Object.entries(userFields).map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&')
})

// ***
// IFRAME URL
// ***
const formSource = computed(() => {
  if (!userParams.value) return ''
  return `${formRoutes[props.formRoute]}?${userParams.value}`
})

// ***
// LISTEN TO FRAME CHANGES
// ***
const zohoFrame = ref<HTMLElement | undefined>()

function onMessageSent(evt : { data: string }) {
  const formInfo = evt.data.split('|')
  if (formInfo.length < 2 || !zohoFrame.value) return

  const zohoFormOffset = 15
  const formHeight = `${parseInt(formInfo[1], 10) + zohoFormOffset}px`
  const iframe = zohoFrame.value
  iframe.style.height = formHeight
}

onMounted(() => window.addEventListener('message', onMessageSent))
onUnmounted(() => window.removeEventListener('message', onMessageSent))

</script>

<template>
  <iframe
    v-if="formSource"
    ref="zohoFrame"
    class="w-full"
    title="zohoFrame"
    :src="formSource"
  />
</template>
