import { http, HttpResponse, RequestHandler } from 'msw'

import { getApiRoute } from '@core/utils/api'

import { getBooking } from '@dashboard/modules/Bookings/api'

import { bookingData } from './mockData'

export const getBookingHandler: RequestHandler = http.get(
  getApiRoute<{ bookingHash: string }>(getBooking.url, { params: { bookingHash: '*' } }),
  () => HttpResponse.json(bookingData),
)
