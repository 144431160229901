import { ModuleRoutes } from '@core/declarations/router'
import { RoleKind } from '@libs/fsc/types/user'

const SearchClub = () => import('@dashboard/modules/Admin/views/SearchClub/SearchClub.vue')

const routes: ModuleRoutes = {
  name: 'Admin',
  basePath: '/',
  roleKind: RoleKind.Admin,
  routes: [
    {
      path: 'admin/search-club',
      name: 'adminSearchClub',
      component: SearchClub,
      meta: {
        tabTitle: 'Search Club',
        navigationType: 'clean',
      },
    },

  ],
}

export default routes
