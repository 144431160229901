import { EndpointConfig } from '@core/store/apiRequest/declarations'

export const getClubByHash: EndpointConfig = {
  storeKey: 'getClubByHash',
  url: '/api/clubs/:clubHash',
}

export const postClubPublicFiles: EndpointConfig = {
  storeKey: 'postClubPublicFiles',
  url: '/api/clubs/:clubHash/files/public',
}

export const getClubEnabledFeatures: EndpointConfig = {
  storeKey: 'getClubEnabledFeatures',
  url: '/api/clubs/:clubHash/features',
}

export const getClubProfiles: EndpointConfig = {
  storeKey: 'getClubProfiles',
  url: '/api/clubs/:clubHash/profiles',
}

export const getClubs: EndpointConfig = {
  storeKey: 'getClubs',
  url: '/api/clubs',
}
