import { EndpointConfig } from '@core/store/apiRequest/declarations'

export const getBookingToken : EndpointConfig = {
  storeKey: 'getBookingToken',
  url: '/api/clubs/:clubHash/booking-token',
}

export const getBooking : EndpointConfig = {
  storeKey: 'getBooking',
  url: '/api/bookings/:bookingHash',
}

export const deleteBooking : EndpointConfig = {
  storeKey: 'deleteBooking',
  url: '/api/bookings/:bookingHash',
}
