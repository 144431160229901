import { ClubStoreShape } from '../declarations'

export const getClubProfilesMock: ClubStoreShape['getClubProfiles'] = {
  '@id': '',
  '@type': '',
  'hydra:totalItems': 1,
  'hydra:member': [
    {
      '@id': '/api/users/722612102/profiles/c78c40b216bfb9dc69f525d69a25912833d9077d',
      '@type': 'Profile',
      hash: 'c78c40b216bfb9dc69f525d69a25912833d9077d',
      user: {
        '@id': '/api/users/722612102',
        '@type': 'User',
        clubRoles: [
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/ba1d1544ddf9f518d11c',
            user: '/api/users/722612102',
            club: '/api/clubs/3abf15a877a6dabfc67806017ab195526a3b011d',
            role: 'ROLE_MANAGER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/2ff47b7bf6be31655cb6',
            user: '/api/users/722612102',
            club: '/api/clubs/3abf15a877a6dabfc67806017ab195526a3b011d',
            role: 'ROLE_GUARDIAN',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/e7a892e1554313d9b935',
            user: '/api/users/722612102',
            club: '/api/clubs/3abf15a877a6dabfc67806017ab195526a3b011d',
            role: 'ROLE_MEMBER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/ffca63cde559838ee57b',
            user: '/api/users/722612102',
            club: '/api/clubs/abd2d140bf7f4c1e59f18f8a7993230767f6e358',
            role: 'ROLE_MEMBER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/9e145b2e4db57ec93d1b',
            user: '/api/users/722612102',
            club: '/api/clubs/abd2d140bf7f4c1e59f18f8a7993230767f6e358',
            role: 'ROLE_GUARDIAN',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/331cc9cf6c6e5139ab2d',
            user: '/api/users/722612102',
            club: '/api/clubs/7e3ad12e75759f7202a5f5a85846859cfc600063',
            role: 'ROLE_MANAGER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/2c4c8f0af46990e330b9',
            user: '/api/users/722612102',
            club: '/api/clubs/598c4c23bbab59f94eee1589535c3e9ef881141a',
            role: 'ROLE_PLAYER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/fe503d27824762305b9e',
            user: '/api/users/722612102',
            club: '/api/clubs/686e5f0520de0c6eb0b65b59515f5b26f5979eff',
            role: 'ROLE_GUARDIAN',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/3fbe999e6dc223b664ae',
            user: '/api/users/722612102',
            club: '/api/clubs/bc2482e5cbd7cda5a1fa72a3464c60288acaeb94',
            role: 'ROLE_MANAGER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/4d242def6f033fa74bdc',
            user: '/api/users/722612102',
            club: '/api/clubs/686e5f0520de0c6eb0b65b59515f5b26f5979eff',
            role: 'ROLE_MEMBER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/22443ec49c4a1d353720',
            user: '/api/users/722612102',
            club: '/api/clubs/880a75730c243f694ab46555bbc090d29f6d25b2',
            role: 'ROLE_MANAGER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/9e52099982c844c68d1d',
            user: '/api/users/722612102',
            club: '/api/clubs/3bc5be3d6f474dc18a67ba3dc1d4740e0efa133f',
            role: 'ROLE_MANAGER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/0de183e987917001fa22',
            user: '/api/users/722612102',
            club: '/api/clubs/3966f58fedd9b14d0e53834efdd8090a13790531',
            role: 'ROLE_GUARDIAN',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/92b79717d208790ed85a',
            user: '/api/users/722612102',
            club: '/api/clubs/f70227df976f9e9055b46569282953098a0be53a',
            role: 'ROLE_MANAGER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/7e7cf9ccf889d18703d1',
            user: '/api/users/722612102',
            club: '/api/clubs/aa28e6227b0aad9949b2788c808c04d7872b8c54',
            role: 'ROLE_MANAGER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/453331521d5305613bea',
            user: '/api/users/722612102',
            club: '/api/clubs/0babb47589b48c64d7fd9f20792c3ccd87004258',
            role: 'ROLE_MANAGER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/8ef2eafdf06a00d43617',
            user: '/api/users/722612102',
            club: '/api/clubs/04f6bf42f0fc229a640c0a3b6da9469d8af8360b',
            role: 'ROLE_GUARDIAN',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/661ca37678a2a64b8afb',
            user: '/api/users/722612102',
            club: '/api/clubs/06a8fb9be84365ca0fe61cbffb64418946358b7d',
            role: 'ROLE_MANAGER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/c291894545705883e3ab',
            user: '/api/users/722612102',
            club: '/api/clubs/ed47541197ba4ace4ced9c8570ac07f9915c0863',
            role: 'ROLE_MANAGER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/70a5019c0e302eeca09a',
            user: '/api/users/722612102',
            club: '/api/clubs/f2b20ce3da3ad59d4135986bd14eefd84b83fdbb',
            role: 'ROLE_MEMBER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/572da65f61ee84c1de56',
            user: '/api/users/722612102',
            club: '/api/clubs/6e2c62e665a64a5a8cd34fb2c98be56a99689b9a',
            role: 'ROLE_MANAGER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/676545cd175548de8352',
            user: '/api/users/722612102',
            club: '/api/clubs/d61990e28735b458c1c74bfc77b65f3af99663a3',
            role: 'ROLE_GUARDIAN',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/efae0c948edb1f11ef8c',
            user: '/api/users/722612102',
            club: '/api/clubs/e7bae3b4a64beb1b2bf2e82a93f2e37927c23a16',
            role: 'ROLE_MANAGER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/4e73dc652c7e2651d5b5',
            user: '/api/users/722612102',
            club: '/api/clubs/e3291aa35e7350c896d763558703ce3e4bbed146',
            role: 'ROLE_MANAGER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/c77f00512d1d1b903899',
            user: '/api/users/722612102',
            club: '/api/clubs/bc2482e5cbd7cda5a1fa72a3464c60288acaeb94',
            role: 'ROLE_GUARDIAN',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/02bb38031a9ed598c794',
            user: '/api/users/722612102',
            club: '/api/clubs/9dce3a93c53f090c1b040410d8b2b4603a322659',
            role: 'ROLE_MANAGER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/5589bc3538ee45ed9edd',
            user: '/api/users/722612102',
            club: '/api/clubs/2dbab7bb6ca24fcdbf6f5f8189da535be838c2b6',
            role: 'ROLE_MANAGER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/0a39c5705a9af9b34f74',
            user: '/api/users/722612102',
            club: '/api/clubs/6fe486c06a0c362cd7237c38ccc674520c01537d',
            role: 'ROLE_MANAGER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/ba68d6a1e4ca7d64a5c5',
            user: '/api/users/722612102',
            club: '/api/clubs/9dce3a93c53f090c1b040410d8b2b4603a322659',
            role: 'ROLE_GUARDIAN',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/488bb2f64247df3e6ed7',
            user: '/api/users/722612102',
            club: '/api/clubs/9dce3a93c53f090c1b040410d8b2b4603a322659',
            role: 'ROLE_MEMBER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/219aab42db1ab8b028db',
            user: '/api/users/722612102',
            club: '/api/clubs/3bc5be3d6f474dc18a67ba3dc1d4740e0efa133f',
            role: 'ROLE_MEMBER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/3480462d2278c712a1b3',
            user: '/api/users/722612102',
            club: '/api/clubs/97f3a71d86f4e0ff1d19f341475b1517e91521e5',
            role: 'ROLE_MANAGER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/edf643d73ca3c92d6437',
            user: '/api/users/722612102',
            club: '/api/clubs/e7bae3b4a64beb1b2bf2e82a93f2e37927c23a16',
            role: 'ROLE_MEMBER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/12d3e07d8819a048b6fe',
            user: '/api/users/722612102',
            club: '/api/clubs/3bc5be3d6f474dc18a67ba3dc1d4740e0efa133f',
            role: 'ROLE_GUARDIAN',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/3b18bb0cc1da6d6b1ed4',
            user: '/api/users/722612102',
            club: '/api/clubs/06a8fb9be84365ca0fe61cbffb64418946358b7d',
            role: 'ROLE_MEMBER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/f66bff8ec05f20141364',
            user: '/api/users/722612102',
            club: '/api/clubs/63eebcb63276e406944151',
            role: 'ROLE_MANAGER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/297459a6f37e5ba930be',
            user: '/api/users/722612102',
            club: '/api/clubs/f2b20ce3da3ad59d4135986bd14eefd84b83fdbb',
            role: 'ROLE_GUARDIAN',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/385f6ffc415682b2b139',
            user: '/api/users/722612102',
            club: '/api/clubs/e3291aa35e7350c896d763558703ce3e4bbed146',
            role: 'ROLE_MEMBER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/3d2453585c5e6123d003',
            user: '/api/users/722612102',
            club: '/api/clubs/02e8e0c4fd4b68172809aabea4e8adf02f2db917',
            role: 'ROLE_MANAGER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/096123a4c26e4a10ebdf',
            user: '/api/users/722612102',
            club: '/api/clubs/6620da555a523546625216',
            role: 'ROLE_MANAGER',
          },
          {
            '@type': 'UserRole',
            '@id': '/api/.well-known/genid/b9d3ce178acc13ba3324',
            user: '/api/users/722612102',
            club: '/api/clubs/6626588565c63290044077',
            role: 'ROLE_MANAGER',
          },
        ],
        username: '722612102',
      },
      club: '/api/clubs/3abf15a877a6dabfc67806017ab195526a3b011d',
      type: 1,
      person: {
        '@type': 'Person',
        '@id': '/api/.well-known/genid/615180d2b68795ea8fde',
        name: 'Juan',
        surname: 'Menganito R.',
      },
    },
  ],
}